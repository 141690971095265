* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  background: #262626;

  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}
#root {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
}
ul {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 1rem;
  width: fit-content;
  max-width: 90vw;
  padding: 0;
}

ul li {
  list-style: none;
}

ul li a {
  width: 55px;
  height: 55px;
  background-color: #fff;
  text-align: center;
  font-size: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  position: relative;
  overflow: hidden;
  border: 3px solid #fff;
  z-index: 1;
  text-decoration: none;
  transition: 0.3s;
}

ul li a .icon {
  position: relative;
  color: #262626;
  transition: 0.5s;
  z-index: 3;
}

ul li a span.icon,
ul li a span.icon svg {
  width: 25px;
  height: 25px;
  fill: #262626;
}

ul li a:hover {
  transform: scale(1.2);
}
ul li a:hover .icon {
  color: #fff;
  transform: rotateY(360deg);
}

ul li a:hover span.icon svg {
  fill: #fff;
}

ul li a:before {
  content: "";
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  height: 110%;
  background: #f00;
  transition: 0.5s;
  z-index: 2;
}

ul li a:hover:before {
  top: -3px;
}

ul li.twitter a:before {
  background: #55acee;
}

ul li.linkedin a:before {
  background: #0077b5;
}

ul li.youtube a:before {
  background: #c20303;
}

ul li.github a:before {
  background: #333333;
}

ul li.facebook a:before {
  background: #3b5999;
}

ul li.instagram a:before {
  background: #f09433;
  background: -moz-linear-gradient(
    45deg,
    #f09433 0%,
    #e6683c 25%,
    #dc2743 50%,
    #cc2366 75%,
    #bc1888 100%
  );
  background: -webkit-linear-gradient(
    45deg,
    #f09433 0%,
    #e6683c 25%,
    #dc2743 50%,
    #cc2366 75%,
    #bc1888 100%
  );
  background: linear-gradient(
    45deg,
    #f09433 0%,
    #e6683c 25%,
    #dc2743 50%,
    #cc2366 75%,
    #bc1888 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f09433', endColorstr='#bc1888',GradientType=1 );
}

ul li.contact a:before {
  background: #f09433;
  background: linear-gradient(45deg, #f4d03f, #16a085);
}

ul li.curiouscat a:before {
  background: #de7c00;
  background: linear-gradient(45deg, #ffa126, #de7c00);
}

.quote {
  max-width: 700px;
  color: #eee;
  padding: 4rem 1rem;
}

.q_mb {
  margin-bottom: 4rem;
}
@media screen and (min-width: 1400px) {
  .q_mb {
    margin-bottom: 8rem;
  }
}

@media screen and (max-width: 550px) {
  .quote {
    padding: 2rem 1rem;
  }
  .q_mb {
    margin-bottom: 2rem;
  }
}

.quote h3 {
  font-size: 2.5rem;
  letter-spacing: 2px;
  text-align: center;
}
.quote span {
  margin-top: 1.5rem;
  display: block;
  text-align: center;
}

.music_form {
  margin-top: 2rem;
  border: 2px dashed #16a085;
  border-radius: 7px;
  padding: 1rem;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  max-width: 99vw;
}
.custom_input {
  background-color: #fff;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.5);
  display: flex;
  cursor: pointer;
  overflow: hidden;

  border-radius: 10rem;
}
.custom_input.open {
  background-color: #16a085;
  border-radius: 7px;
}

.custom_input__icon {
  font-size: 1.5rem;
  height: 3.5rem;
  width: 3.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.custom_input.open .custom_input__icon {
  color: #fff;
}

.custom_input__input {
  animation: inputOut 0.5s forwards ease-in-out;
  overflow: hidden;
  width: 0;
}
.custom_input__input input {
  height: 100%;
  width: 100%;
  font-size: 1.1rem;
  padding: 0.5rem;
  outline: none;
  display: none;
  transition: all 0.5s;
}

.custom_input.open .custom_input__input {
  width: 100%;
  animation: inputIn 0.5s forwards ease-in-out;
}
.custom_input.open .custom_input__input input {
  display: block;
  min-width: 16rem;
  max-width: 60vw;
}

@media screen and (max-width: 650px) {
  .custom_input.open .custom_input__input input {
    min-width: 40rem;
  }
}

@keyframes inputIn {
  0% {
    width: 0;
  }
  2% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}
@keyframes inputOut {
  0% {
    width: 100%;
  }
  100% {
    width: 0;
  }
}

.custom_send__icon {
  font-size: 1.5rem;
  height: 3.5rem;
  width: 3.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10rem;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.5);

  background-color: #16a085;
  color: #fff;
  cursor: pointer;

  transition: all 0.3s;
}

.custom_send__icon:disabled {
  background-color: #fff;
  cursor: not-allowed;
  color: #262626;
}
.custom_send__icon.active {
  background-color: #16a085;
  color: #fff;
  cursor: pointer;
}
.custom_send__icon:hover:not(:disabled) {
  transform: scale(1.05);
}

.custom_send__icon i {
  transform: translate(0, 0);
}

.custom_send__icon.loading i {
  animation: iconLoader 1s infinite ease-in-out;
}

@keyframes iconLoader {
  0% {
    transform: translate(0, 0);
  }
  50% {
    transform: translate(-0.3rem, 0.3rem);
  }
  100% {
    transform: translate(0, 0);
  }
}

.error {
  width: 100%;
  display: block;
  padding: 1rem;
  color: #dc2743;
}
